import {Component, OnInit} from '@angular/core';
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {ActivatedRoute, Router} from "@angular/router";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {PwaSessionContextProvider} from "../service.pwa-session-context/pwa-session-context";
import {ClusterPageModel} from "./ClusterPageModel";
import {FirebaseCluster} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseCluster";
import {
  FirebaseConnectionService
} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {AppCluster} from "../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {AppPageDefinitionSet} from "../javascript.lib.mojo-base/model/app/AppPageDefinitionSet";
import {AppAnswerSet} from "../javascript.lib.mojo-base/model/AppAnswerSet";
import {AppClusterAnswers, IAppClusterAnswers} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterAnswers";
import {AppAnswer} from "../javascript.lib.mojo-base/model/AppAnswer";
import {FirebaseClusterAnswers} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseClusterAnswers";
import {environment} from "../environments/environment";
import {AppStorage} from "../app/AppStorage";
import {FirebaseSaver2} from "../browser.lib.evaluation-tool/util/FirebaseSaver2";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MatDialog} from "@angular/material/dialog";
import {PhotosProvider} from "../browser.lib.evaluation-tool/module.evaluation-services/service.photos/photos";
import {AppQuestion} from "../javascript.lib.mojo-base/model/AppQuestion";
import {FirebaseProperty} from "../javascript.lib.mojo-base/firebase/realtime-database/properties/FirebaseProperty";
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";

@Component({
  selector: 'page-cluster-page',
  styleUrls: ['page-cluster-page.component.scss'],
  templateUrl: './page-cluster-page.component.html'
})
export class PageClusterPageComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'PageClusterPageComponent' );

  public property: AppProperty = null;

  public cluster: AppCluster;

  isSaving: boolean = false;
  public activeIndex = 0;
  public pageDefinitions: AppPageDefinitionSet;

  public clusterAnswers: AppClusterAnswers;
  public answers: AppAnswerSet;
  public pageAnswers: AppAnswer[];

  private _firebaseSaver: FirebaseSaver2;



  // pages: ClusterPageModel[] = [
  //
  //   new ClusterPageModel( {
  //     pageNo: 1,
  //   }),
  //   new ClusterPageModel( {
  //     pageNo: 2,
  //   }),
  //   new ClusterPageModel( {
  //     pageNo: 3,
  //   }),
  //   new ClusterPageModel( {
  //     pageNo: 4,
  //   }),
  // ];



  async saveAspectAndPhotos( goingToHomeAfter: boolean) {

    this.isSaving = true;
    try {

      if( goingToHomeAfter ) {

        for( const question of this.answers.questions.questions ) {

          // force some sort of an answer to be created for all questions
          this.answers.getAnswer( question );
        }
      }

      this.answers.scrubMarkupTagsFromTextAnswers(); // report-7152.remediation.md: Page 19: Lack of Input Validation(stored)
      this.clusterAnswers.update( this.answers );
      await FirebaseClusterAnswers.writeValueObject( this.firebase, this.property.propertyKey, this.clusterAnswers );


      await this._firebaseSaver.savePhotos( this.sessionContext.propertyContext.propertyKey );

    } finally {
      this.isSaving = false;
    }
  }

  private shouldSkipIndex( sectionIndex ): boolean {

    if( sectionIndex < 0 ) {

      return false;
    }

    if( sectionIndex >= this.pageDefinitions.value.length ) {

      return false;
    }

    const pageDefinition = this.pageDefinitions.value[sectionIndex];

    if( !pageDefinition ) {

      return false;
    }

    const dependantDescriptor = pageDefinition.value.dependantDescriptor;
    if( !dependantDescriptor ) {

      return false;
    }

    const dependantQuestion: AppQuestion = this.answers.questions.getQuestionById(dependantDescriptor.questionKey);

    if( !dependantQuestion ) {

      this._log.error( '!dependantQuestion', sectionIndex, dependantDescriptor );
      return false;
    }

    const dependantAnswer = this.answers.getAnswer( dependantQuestion );
    if( !dependantAnswer ) {

      this._log.error( '!dependantAnswer', dependantQuestion );
      return false;
    }

    if( dependantAnswer.isTruthy(dependantDescriptor)) {

      return false;
    }

    this._log.debug( 'dependantAnswer', dependantAnswer );
    return true;
  }


  async onPrevious() {


    this.clusterAnswers.update( this.answers );

    await this.saveAspectAndPhotos( false );

    // if( 0 === this.activeIndex ) {
    //
    //   this._log.debug( '0 === this.activeIndex' );
    //   AppRouteManifest.CLUSTER.navigate( this.router );
    //   return;
    // }

    let nextActiveIndex = this.activeIndex - 1;

    while( this.shouldSkipIndex(nextActiveIndex) ) {

      nextActiveIndex--;
    }

    if( 0 > nextActiveIndex ) {

      await this.saveAspectAndPhotos( true );
      AppRouteManifest.CLUSTER.navigate( this.router );
    } else {

      AppRouteManifest.CLUSTER_PAGE.navigateToPage( this.router, this.cluster._self.toString, nextActiveIndex );
    }

  }

  async onNext() {


    this.clusterAnswers.update( this.answers );

    await this.saveAspectAndPhotos( false );

    // // last page ?
    // if( this.pageDefinitions.value.length - 1 === this.activeIndex ) {
    //
    //   this.cluster.value.completed = true;
    //   await FirebaseCluster.writeReference( this.firebase, this.propertyKey, this.cluster );
    //
    //   await this.saveAspectAndPhotos( true );
    //   AppRouteManifest.CLUSTER.navigate( this.router );
    //   return;
    // }

    let nextActiveIndex = this.activeIndex + 1;

    while( this.shouldSkipIndex(nextActiveIndex) ) {

      nextActiveIndex++;
    }

    // last page ?
    if( this.pageDefinitions.value.length <= nextActiveIndex ) {

      this.cluster.value.completed = true;
      await FirebaseCluster.writeReference( this.firebase, this.property.propertyKey, this.cluster );

      await this.saveAspectAndPhotos( true );
      AppRouteManifest.CLUSTER.navigate( this.router );

    } else {

      AppRouteManifest.CLUSTER_PAGE.navigateToPage( this.router, this.cluster._self.toString, nextActiveIndex );
    }

  }

  private async _onInitAnswerFields( clusterId: string ) {

    if( this.answers ) {
      return;
    }

    this.cluster = await FirebaseCluster.readReference( this.firebase, this.property.propertyKey, clusterId );
    this._log.debug( 'this.cluster', this.cluster );

    const product = NocoDbProduct.INSTANCE;
    this._log.debug( 'this.cluster', this.cluster );
    this._log.debug( 'product.clusterQuestions', product.clusterQuestions );
    this.pageDefinitions = product.clusterQuestions.toPageDefinitions( this.cluster, product.evaluationQuestions );
    this._log.debug( 'this.pageDefinitions', this.pageDefinitions );


    const questionSet = this.pageDefinitions.toQuestionSet( environment.productConfig.product.questions );
    this._log.debug( 'questionSet', questionSet );

    // get the answers ...
    {
      const clusterAnswersValue: IAppClusterAnswers = await FirebaseClusterAnswers.readValue( this.firebase, this.property.propertyKey, this.cluster );
      if( clusterAnswersValue ) {

        this.clusterAnswers = new AppClusterAnswers( clusterAnswersValue );
      } else { // first time answering these questions

        this.clusterAnswers = AppClusterAnswers.build( this.cluster._self.value.id );
      }
    }
    this._log.debug( 'this.clusterAnswers', this.clusterAnswers );

    this.answers = this.clusterAnswers.toAnswerSet( questionSet );
    this._log.debug( 'this.answers', this.answers );
  }

  private async _onInit() {

    if( this.sessionContext.propertyContext ) {

      this.property = this.sessionContext.propertyContext.property;
    } else {

      const propertyKey = AppStorage.getPropertyKey();
      this.property = await FirebaseProperty.readReferenceRedux( this.firebase, propertyKey );
    }

    const clusterId = await AppRouteManifest.CLUSTER_PAGE.getClusterId( this.route );
    this._log.debug( 'clusterId', clusterId );

    await this._onInitAnswerFields( clusterId );

    this.activeIndex = await AppRouteManifest.CLUSTER_PAGE.getActiveIndex( this.route );
    this._log.debug( 'this.activeIndex', this.activeIndex );
    this._log.debug( 'this.pageDefinitions', this.pageDefinitions );
    this._log.debug( 'this.pageDefinitions.value[this.activeIndex]', this.pageDefinitions.value[this.activeIndex] );
    const questionKeys = this.pageDefinitions.value[this.activeIndex].value.questionKeys;
    this.pageAnswers = this.answers.getAnswers( questionKeys );

    this._log.debug( 'this.pageAnswers', this.pageAnswers );
  }


  ngOnInit() {


    this.route.paramMap.subscribe( async (params) => {

      this._onInit();
    });

    this._onInit();
  }

  onNoOp() {

    this._log.debug( 'onNoOp' );
  }


  constructor( public router: Router,
               public route: ActivatedRoute,
               public sessionContext: PwaSessionContextProvider,
               public firebase: FirebaseConnectionService,
               public afDb: AngularFireDatabase,
               public dialog: MatDialog,
               public photos: PhotosProvider,
  ) {

    this._firebaseSaver = new FirebaseSaver2( afDb, photos, dialog );

  }

}
